import { HelpersService } from './../../services/helpers/helpers.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  data;
  constructor(private helpers: HelpersService) {}

  ngOnInit() {
  }

  close() {
    this.helpers.modalCtrl.dismiss();
  }

}
