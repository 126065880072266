import { Component, HostListener } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth/auth.service';
import { NavigationExtras } from '@angular/router';
import { LoginRegisterPage } from 'src/app/pages/login-register/login-register.page';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  header: Array<any> = [];
  ObjectKeys = Object.keys;
  emailVerified = false;
  isLoggedIn = false;
  private isclick = true;
  spinner: false;

  scrHeight: any;
  scrWidth: any;
  screenSizeSmall = false;
  ready = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;

    this.screenSizeSmall = false;
    if (window.innerWidth < 700) {
      this.screenSizeSmall = true;
    }
  }

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private afAuth: AuthService,
    private modalCtrl: ModalController
  ) {

    this.afAuth.helpers.eventsCtrl.subscribe('setMenuHeader', (header) => {
      this.header = header;
    });

    this.afAuth.helpers.eventsCtrl.subscribe('setIsLoggedIn', (isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    this.afAuth.helpers.eventsCtrl.subscribe('setEmailVerified', (emailVerified) => {
      this.emailVerified = emailVerified;
    });

    this.afAuth.helpers.eventsCtrl.subscribe('loginUser', (user) => {
      if (user) {
        this.emailVerified = this.afAuth.emailVerification;
        this.isLoggedIn = true;
      } else {
        this.spinner = false;
        this.isLoggedIn = false;
      }
      this.spinner = false;
    });
    // this.events.push('loginUser');

    this.initializeApp();
    this.getScreenSize();



    this.afAuth.helpers.getStoreData('messageCookie').then((value) => {
      if (value) {
        this.ready = false;
      } else {
        this.ready = true;
      }
    });

  }

  switchLanguage(language: string) {
    this.afAuth.helpers.translate.use(language);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.afAuth.helpers.displayPlatform()) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
      this.afAuth.helpers.translate.setDefaultLang('en');
    });
  }

  openPage(page: string, navigationParams: any = null) {
    const url: string = this.afAuth.helpers.routerCtrl.url;
    const navigationExtras: NavigationExtras = {
      queryParams: navigationParams
    };
    if (page === 'home') {
      this.afAuth.helpers.navCtrl.navigateRoot([`home`]);
    } else if (page === 'my_properties') {
      if (url.indexOf(page) > -1) {
        this.afAuth.helpers.eventsCtrl.publish('openCreatePost');
      } else {
        page = 'posts';
        this.afAuth.helpers.routerCtrl.navigate(['my-properties'], navigationExtras);
      }
    } else {
      this.afAuth.helpers.routerCtrl.navigate([`${page}`]);
    }
  }

  log() {
    if (!this.isLoggedIn) {
      this.loginRegister();
    } else {
      this.logout();
    }
  }

  loginRegister() {
    this.openModal(LoginRegisterPage);
  }

  createPost() {
    if (!this.afAuth.getCurrentUser().currentUser) {
      this.loginRegister();
      this.afAuth.helpers.presentAlert('Please Login to create a post, if you do not have an account you can sign up for free!!');
    } else {
      const params = {
        createPost: true
      };
      this.openPage('my_properties');
      setTimeout(() => {
        this.openPage('my_properties', params);
      }, 1000);
    }
  }

  async openModal(modalPage: any, data: any = null, cssClass: string = 'modal90', widthPointer: number = 500) {
    if (this.isclick) {
      this.isclick = false;

      if (this.scrWidth <= widthPointer) {
        cssClass = 'modal100100';
      }
      const modal = await this.modalCtrl.create({
        component: modalPage,
        componentProps: { data: data },
        cssClass: cssClass,
        backdropDismiss: false
      })
      await modal.present();
      modal.onDidDismiss()
        .then(() => {
          this.isclick = true;
        });
    }
  }

  logout() {
    this.isLoggedIn = false;
    this.afAuth.logout(true, 'logoutUser');
  }

  closeMessage() {
    const messageCookie = { messageCookie: true };
    this.afAuth.helpers.storeData(messageCookie);
    this.ready = false;
  }


}
