import { PostComponent } from './post/post.component';
import { MessagePopupComponent } from './message-popup/message-popup.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { SubscriptionPaymentComponent } from './subscription-payment/subscription-payment.component';
import { PrivacyandpolicyComponent } from "./privacyandpolicy/privacyandpolicy.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./footer/footer.component";
import { IonicModule } from "@ionic/angular";
import { HeaderComponent } from "./header/header.component";
import { FormsModule ,ReactiveFormsModule } from "@angular/forms";
import { TermsandconditionsComponent } from "./termsandconditions/termsandconditions.component";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    TermsandconditionsComponent,
    PrivacyandpolicyComponent,
    SubscriptionPaymentComponent,
    UserManagementComponent,
    MessagePopupComponent,
    PostComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, IonicModule, FormsModule ],
  exports: [
    HeaderComponent,
    FooterComponent,
    TermsandconditionsComponent,
    PrivacyandpolicyComponent,
    SubscriptionPaymentComponent,
    UserManagementComponent,
    MessagePopupComponent,
    PostComponent
  ]
})
export class SharedComponentsModule {}
