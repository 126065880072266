import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoginRegisterPage } from 'src/app/pages/login-register/login-register.page';
import { NavigationExtras } from '@angular/router';
import { HostListener } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  private isClick = true;
  private events: Array<string> = [];
  emailVerified = false;
  isLoggedIn = false;
  spinner = false;
  ObjectKeys = Object.keys;

  header = {
    home: { color: 'dark', fill: 'clear', text: 'Home', value: 'home' },
    rent: { color: 'dark', fill: 'clear', text: 'Rentals', value: 'rentals' },
    sale: { color: 'dark', fill: 'clear', text: 'Sales', value: 'sales' },
    about: { color: 'dark', fill: 'clear', text: 'About Us', value: 'about' },
    my_properties: { color: 'dark', fill: 'clear', text: 'My Porperties', value: 'my-properties' },
  };
  headerBack = JSON.parse(JSON.stringify(this.header));
  headerName = 'Home';

  menu: Array<any> = [
    { text: 'About us', scroll: 'aboutus' },
    { text: 'How it works', scroll: 'howitworks',
      subTitles: [{ text: 'Selling', scroll: 'selling' }, { text: 'Buying', scroll: 'buying' }] },
    { text: 'What you need to know', scroll: 'whatyouneedtoknow',
      subTitles: [{ text: 'Morgage', scroll: 'morgage' }, { text: 'Property Solicitors', scroll: 'propertysolicitors' }] },
    { text: 'Contracts', scroll: 'contracts' },
    { text: 'FAQ', scroll: 'faq' },
    { text: 'Terms and conditions', scroll: 'termsandconditions' },
    { text: 'Privacy and Policy', scroll: 'privacyandpolicy' },
    { text: 'Contact us', scroll: 'contactus' }
  ];

  scrHeight: any;
  scrWidth: any;
  showMenu = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.showMenu = false;
    if (window.innerWidth < 790) {
      this.showMenu = true;
    }
  }

  constructor(
    public afAuth: AuthService, private cd: ChangeDetectorRef, public menuCtrl: MenuController) {

    this.afAuth.helpers.eventsCtrl.subscribe('loginUser', (user) => {
      if (user) {
        this.emailVerified = this.afAuth.emailVerification;
        this.isLoggedIn = true;
      } else {
        this.spinner = false;
        this.isLoggedIn = false;
      }
      this.spinner = false;
    });
    this.events.push('loginUser');

    this.afAuth.helpers.eventsCtrl.subscribe('logoutUser', () => {
      this.spinner = false;
      this.isLoggedIn = false;
    });
    this.events.push('logoutUser');

    this.afAuth.helpers.eventsCtrl.subscribe('openPageFromMenu', (page, navigationExtras) => {
      this.selectTab(page);
    });
    this.events.push('openPageFromMenu');

    this.afAuth.helpers.eventsCtrl.subscribe('logFromMenu', () => {
      this.log();
    });
    this.events.push('logFromMenu');


    this.afAuth.helpers.eventsCtrl.subscribe('createPostFromMenu', () => {
      this.createPost();
    });
    // this.events.push("createPostFromMenu");

    this.afAuth.helpers.eventsCtrl.subscribe('changeMenuHeader', (option) => {
      this.selectTab(option);
    });
    this.events.push('changeMenuHeader');


    this.getScreenSize();
  }

  ngOnInit() {
    this.setLogin();
    this.getUrl();
  }

  ngOnDestroy() {
    this.events.forEach(event => {
      this.afAuth.helpers.eventsCtrl.unsubscribe(event);
    });
  }

  openMenu() {
    this.menuCtrl.open();
  }

  log() {
    if (!this.isLoggedIn) {
      this.loginRegister();
    } else {
      this.logout();
    }
  }

  loginRegister() {
    this.openModal(LoginRegisterPage);
  }

  createPost() {
    if (!this.isLoggedIn) {
      this.loginRegister();
      this.afAuth.helpers.presentAlert('Please Login to create a post, if you do not have an account you can sign up for free!!');
    } else {
      const params = {
        createPost: true
      };
      this.openPage('my_properties');
      setTimeout(() => {
        this.openPage('my_properties', params);
      }, 1000);
    }
  }

  logout() {
    // this.spinner = true;
    this.afAuth.logout(true, 'logoutUser');
  }

  setLogin() {
    this.spinner = true;
    if (this.afAuth.getCurrentUser().currentUser) {
      this.emailVerified = this.afAuth.emailVerification;
      this.afAuth.helpers.eventsCtrl.publish('setEmailVerified', this.emailVerified);
      this.isLoggedIn = true;
      this.afAuth.helpers.eventsCtrl.publish('setIsLoggedIn', this.isLoggedIn);
      this.spinner = false;
    } else {
      this.spinner = false;
      // this.afAuth.setUserVariables(true, "loginUser", true);
    }
  }

  async openModal(modalPage: any, data: any = null) {
    if (this.isClick) {
      this.isClick = false;
      const modal = await this.afAuth.helpers.modalCtrl.create({
        component: modalPage,
        componentProps: { data: data },
        cssClass: 'modal90',
        backdropDismiss: false
      })

      await modal.present();
      modal.onDidDismiss()
        .then(() => {
          this.isClick = true;
          this.setLogin();
        });
    }
  }

  openPage(page: string, navigationParams: any = null) {
    const url: string = this.afAuth.helpers.routerCtrl.url;
    const navigationExtras: NavigationExtras = {
      queryParams: navigationParams
    };
    if (page === 'home') {
      this.afAuth.helpers.navCtrl.navigateRoot([`home`]);
    } else if (page === 'my_properties') {
      if (url.indexOf(page) > -1) {
        this.afAuth.helpers.eventsCtrl.publish('openCreatePost');
      } else {
        page = 'posts';
        this.afAuth.helpers.routerCtrl.navigate(['my-properties'], navigationExtras);
      }
    } else {
      this.afAuth.helpers.routerCtrl.navigate([`${page}`]);
    }
  }

  getUrl(clean: boolean = false) {
    const url: string = this.afAuth.helpers.routerCtrl.url;
    if (url.indexOf('home') > -1) {
      this.selectTab('home');
    } else if (url.indexOf('rentals') > -1) {
      this.selectTab('rent', clean);
    } else if (url.indexOf('sales') > -1
    ) {
      this.selectTab('sale', clean);
    } else if (url.indexOf('about') > -1) {
      this.selectTab('about');
    } else if (url.indexOf('contact') > -1) {
      this.selectTab('contactus');
    } else if (url.indexOf('my-properties') > -1) {
      this.selectTab('my_properties');
    }
  }

  selectTab(buttonName: string, clean: boolean = false) {
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < Object.keys(this.header).length; index++) {
      this.header[Object.keys(this.header)[index]].fill = 'clear';
      this.header[Object.keys(this.header)[index]].color = 'dark';
    }
    this.header[buttonName].color = 'warning';
    this.header[buttonName].fill = 'solid';
    this.headerName = this.header[buttonName].text;
    this.afAuth.helpers.eventsCtrl.publish('setMenuHeader', this.header);
  }

  resendEmailVerification() {
    this.afAuth.getCurrentUser().currentUser.sendEmailVerification().then(() => {
      this.afAuth.helpers.presentToast('Verification email sent');
    });
    // this.afAuth.callCloudFunction('resendVerificationEmail', null);
  }


}
