import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {

  contactForm: FormGroup;
  errorMessages: object;
  spinner = false;
  private events: Array<string> = [];

  listOne = [
    { text: 'About Us', page: 'about', value: 'aboutus' },
    { text: 'FAQs', page: 'about', value: 'faq' },
    { text: 'Terms & Conditions', page: 'about', value: 'termsandconditions' },
    // {text: 'About Us', value: 'about'},
  ];

  listTwo = [
    { text: 'Sell with Us', page: 'about', value: 'selling' },
    { text: 'Buy with us', page: 'about', value: 'buying' },
  ];

  lisThree = [
    { text: 'Contact us', page: 'about', value: 'contactus' },
  ];

  constructor(private formBuilder: FormBuilder,
              public afAuth: AuthService) {
    this.errorMessages = this.afAuth.helpers.getErrorMessages();

    this.contactForm = formBuilder.group({
      name: ['', Validators.compose([
        Validators.required, Validators.pattern('[a-zA-Z0-9 ]*'),
        Validators.minLength(2),
        Validators.maxLength(30)
      ])],
      email: ['', Validators.compose([
        Validators.required, Validators.pattern('.*\@.*\..*'),
        Validators.minLength(6),
        Validators.maxLength(50)
      ])],
      message: ['', Validators.compose([
        Validators.required, Validators.pattern('[a-zA-Z]*'),
        Validators.minLength(100),
        Validators.maxLength(1000)
      ])],
    });

    this.afAuth.helpers.eventsCtrl.subscribe('messageSubmitted', (data) => {
      if (data) {
        this.afAuth.helpers.presentToast('Message Submitted');
        this.contactForm.reset();
      }
      this.spinner = false;
    });
    this.events.push('messageSubmitted');
  }

  ngOnInit() { }

  ngOnDestroy() {
    // console.log("On Destroy");
    this.events.forEach(event => {
      // console.log(event);
      this.afAuth.helpers.eventsCtrl.unsubscribe(event);
    });
  }


  openPage(page: string, section: string = null) {
    // console.log(page)
    let navigationExtras: NavigationExtras;
    if (page === 'home') {
      this.afAuth.helpers.navCtrl.navigateBack(`${page}`);
    }
    this.afAuth.helpers.navCtrl.navigateForward([`${page}`]);
    if (page === 'about') {
      navigationExtras = {
        queryParams: {
          section: section
        }
      };
      page = 'posts';
      this.afAuth.helpers.navCtrl.navigateRoot(['about/']);
      setTimeout(() => {
        this.afAuth.helpers.navCtrl.navigateRoot(['about/'], navigationExtras);
      }, 1000);
    }
  }

  submitContactMessage() {
    this.spinner = true;
    this.afAuth.callCloudFunction('submitContactMessage', this.contactForm.value, 'messageSubmitted');
  }

}
