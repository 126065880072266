import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  getDayNumber(n): string {
    return moment().add(n, 'days').format('YYYY-MM-DD');
  }

  getTimeHour(): string {
    return moment().format('H');
  }

  addDayNumber(day, n) {
    return moment(day).add(n, 'days').valueOf();
  }

  getDateFromString(string: string) {
    return moment(string).format("YYYY-MM-DD")
  }

  getDateFromTimestamp(timestamp, format: string = 'YYYY-MM-DD', unix: boolean = false) {
    if (unix) {
      return moment.unix(timestamp).format(format);
    }
    return moment(timestamp).format(format);
  }

  getDateToStringFormat(date: any, format: string = 'YYYY-MM-DD') {
    return moment(date).format(format);
  }

  getTimeDifference(eventDate, eventStarttime): string {
    console.log(eventDate);
    console.log(eventStarttime);
    const duration = moment(`${eventDate}, ${eventStarttime}`, "YYYY-MM-DD, h:mm:ss a").fromNow();
    console.log(duration);
    return duration;
  }

  getMoment() {
    return moment();
  }
}
