import { Injectable } from '@angular/core';
import { Platform, AlertController, LoadingController, ToastController, NavController, Events, ModalController, PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from '../../services/country/country.service';
import { DateService } from '../../services/date/date.service';
import { Form, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { ImageService } from '../image/image.service';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { Clipboard } from 'ts-clipboard';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  private loading: any;
  private loadingCheck = false;

  postpurpose: Array<string> = [
    'Rent',
    'Sale'
  ];
  propertyTypes: Array<any> =  ['Flat', 'Detached house', 'Terraced house', 'Semi-detached house', 'Commercial' ];
  
  // [
  //   'Apartment',
  //   'House',
  //   'Flat',
  //   // 'Studio',
  //   'Warehouse',
  //   // 'Room',
  //   // 'Double Room',
  //   'Commercial'
  // ];

  priceTypes: Array<string> = [
    'pcm',
    'pw'
  ];

  rentPriceTypes: Array<string> = [
    'pcm',
    'pw'
  ];

  salePriceTypes: Array<string> = [
    'Full Price',
    // 'From',
    // 'Offer over',
    // 'Guide Price',
    // 'Offers in region of'
  ];


  postPropertyAge: Array<string> = [
    '0-5',
    '6-10',
    '11-15',
    '16-20',
    '20+'
  ];

  extraAtributes = [
    {
      value: 'Parking',
      color: 'dark',
      list: [
        { value: 'Garage', view: true },
        { value: 'Drive way', view: true },
        { value: 'Residensial Parking', view: true },
        { value: 'Off Street Parking', view: true }
      ]
    },
    {
      value: 'Gardem',
      color: 'success',
      list: [
        { value: 'Front garden', view: true },
        { value: 'Back garden', view: true },
        { value: 'Shared garden', view: true },
        { value: 'Private garden', view: true }
      ]
    },
    {
      value: 'Facilities',
      color: 'primary',
      list: [
        { value: 'Consierge', view: true },
        { value: 'Gym', view: true },
        { value: 'Pool', view: true },
        { value: 'Sonnar', view: true }
      ]
    }
  ];

  filters = [
    { value: 'postID', text: 'Recent' },
    { value: 'purpose', text: 'purpose' },
    { value: 'type', text: 'Type' },
    { value: 'price', text: 'Price' },
    // { value: 'propertyAge', text: 'Porperty Age' },
    { value: 'numberOfBeds', text: 'Number of beds' },
    { value: 'numberOfBaths', text: 'Number of baths' },
    { value: 'furnished', text: 'Furnished' },
    { value: 'postcode', text: 'Post code' }
  ];


  propertySortOptions = [
    { value: 'latest', text: 'Latest' },
    { value: 'old', text: 'Oldest' },
    { value: 'rent', text: 'Rentals' },
    { value: 'sale', text: 'Sales' },
    { value: 'live', text: 'Live' },
    { value: 'expired', text: 'Expired' },
    { value: 'disabled', text: 'Sold/Rented' },
  ];

  private sliderConfig = {
    spaceBetween: 10,
    centeredSlides: true,
    slidesPerView: 1
  };

  private filtersOptions: Array<any> = ['title', 'price', 'purpose', 'type', 'address', 'towncity', 'county', 'postcode']

  towncities = [
    'Beckenham',
    'Brentford',
    'Bromley',
    'Carshalton',
    'Chessington',
    'Chislehurst',
    'Coulsdon',
    'Croydon',
    'Edgware',
    'Enfield',
    'Feltham',
    'Greenford',
    'Hampton',
    'Harrow',
    'Hayes',
    'Hounslow',
    'Isleworth',
    'Kenley',
    'Keston',
    'Kingston upon Thames',
    'London',
    'Mitcham',
    'Morden',
    'New Malden',
    'Northolt',
    'Northwood',
    'Orpington',
    'Pinner',
    'Purley',
    'Ruislip',
    'South Croydon',
    'Southall',
    'Stanmore',
    'Surbiton',
    'Sutton',
    'Teddington',
    'Thornton Heath',
    'Twickenham',
    'Uxbridge',
    'Wallington',
    'Wembley',
    'West Drayton',
    'West Wickham',
    'Worcester Park'

  ];

  packages = [
    { name: 'basic', value: 39.99, discount: 0, total: 39.99, weeks: 8, color: null,
    features:['Advert will be live for 8 weeks.', 'Max of 15 images'],
    text: 'Advert will be live for 8 weeks.', start: new Date().valueOf(),
    end: this.dateCtrl.addDayNumber(new Date().valueOf(), 8 * 7) },
    { name: 'premium', value: 54.99, discount: 0, total: 54.99, weeks: 12, color: null,
    features: ['Advert will be live for 8 weeks.', 'Max of 15 images', 'Advert will show up top of the list when searched in your area.'],
    text: 'Advert will be live for 12 weeks and will show up top of the list when searched in your area.', start: new Date().valueOf(),
    end: this.dateCtrl.addDayNumber(new Date().valueOf(), 12 * 7) },
    // { name: 'ultimate', value: 69.99, discount: 0, total: 69.99, weeks: 12, color: null, text:
    // 'Advert will be live for 12 weeks and will show up top in your area for 2 weeks as well as
    // recommended propety on the home page for 48 hours. - £69.99', start: new Date().valueOf, end:
    // this.dateCtrl.addDayNumber(new Date().valueOf(), 12 * 7) }
  ];


  constructor(
    public platform: Platform,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    public routerCtrl: Router,
    public navCtrl: NavController,
    public translate: TranslateService,
    public countryCtrl: CountryService,
    public dateCtrl: DateService,
    public eventsCtrl: Events,
    public storageCtrl: Storage,
    public imageCtrl: ImageService,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    private formBuilder: FormBuilder,
  ) {
  }


  // SHOW LOADING ===================================================================
  async presentLoading() {
    this.loading = await this.loadingCtrl.create({
      message: 'Please wait... do not refresh',
      spinner: 'crescent'
    });
    this.loadingCheck = true;
    return await this.loading.present();
  }

  // HIDE LOADING ===================================================================
  async hideLoading() {
    await this.loadingCtrl.getTop().then((loader) => {
      if (loader) {
        return this.loading.dismiss();
      }
    });
  }

  // DISPLAY TOAST ===================================================================
  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

  // DISPLAY ALERT ===================================================================
  async presentAlert(message: string, title: string = '') {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: title,
      message: message,
      buttons: ['OK']
    });
    await alert.present();
  }

  async presentAlertConfirm(message: string, eventPublish: string, data: any = null) {
    const alert = await this.alertCtrl.create({
      header: 'Confirm!',
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return false;
          }
        }, {
          text: 'Okay',
          handler: () => {
            this.eventsCtrl.publish(eventPublish, data);
          }
        }
      ]
    });

    await alert.present();
  }

  // DISPLAY MODAL ===================================================================
  async presentModal(modalPage: any) {
    const modal = await this.modalCtrl.create({
      component: modalPage,
      backdropDismiss: false
    });
    await modal.present();
  }

  // DISPLAY POPOVER ===================================================================
  async presentPopover(ev: any, items: any, eventPublish: string) {
    const popover = await this.popoverCtrl.create({
      component: PopoverComponent,
      event: ev,
      translucent: true,
      componentProps: { data: items }
    });
    await popover.present();

    popover.onDidDismiss().then((data) => {
      this.eventsCtrl.publish(eventPublish, data.data);
    });
  }

  // CHECK PLATFORM ===================================================================
  displayPlatform(): boolean {
    if (this.platform.is('cordova')) {
      return true;
    } else {
      return false;
    }
  }
  // NAVIGATE FORWARD ===================================================================
  pushPage(page: string, id: string = '') {
    this.routerCtrl.navigate([`${page}/${id}`]);
  }

  // NAVIGA ROOT ===================================================================
  rootPage(page: string, data: any = '') {
    this.navCtrl.navigateRoot(page, data)
  }

  // TRANSLATE STRING ===================================================================
  getTranslate(text: string, str: string = ''): string {
    let translation: string;
    const params = { value: str };
    this.translate.get(text, params).subscribe(res => {
      translation = res;
    });
    return translation;
  }

  // CONVERT 1ST CHART TO UPPER ===================================================================
  convertFirstCharTotoUpper(text: string, resToLower: boolean = true, capAfterSpace: boolean = false): string {
    if (resToLower) {
      text = text.toLowerCase();
    }

    const char = text.charAt(0);
    let str = text.replace(char, text.charAt(0).toUpperCase());

    if (capAfterSpace) {
      let newString = '';
      const split = text.split(' ');
      split.forEach(word => {
        {
          newString += ' ' + this.convertFirstCharTotoUpper(word);
        }
      });
      str = newString;
    }

    return str;
  }

  // GET CONTRIES OBJECT ===================================================================
  getCountries(): Array<object> {
    return this.countryCtrl.getCountries();
  }

  // ERROR MESSAGESS ===================================================================
  getErrorMessages() {
    const errorMessages = {
      smallText: [
        { type: 'required', message: this.getTranslate('FORMERRORS.required') },
        { type: 'minlength', message: this.getTranslate('FORMERRORS.minLength', '2') },
        { type: 'maxlength', message: this.getTranslate('FORMERRORS.maxLength', '30') },
        { type: 'pattern', message: this.getTranslate('FORMERRORS.letters') }
      ],
      email: [
        { type: 'required', message: this.getTranslate('FORMERRORS.required') },
        { type: 'minlength', message: this.getTranslate('FORMERRORS.minLength', '6') },
        { type: 'maxlength', message: this.getTranslate('FORMERRORS.maxLength', '30') },
        { type: 'pattern', message: this.getTranslate('FORMERRORS.email') }
      ],
      number: [
        { type: 'required', message: this.getTranslate('FORMERRORS.required') },
        { type: 'maxlength', message: this.getTranslate('FORMERRORS.maxLength', '6') },
        { type: 'pattern', message: this.getTranslate('FORMERRORS.number') }
      ],
      phonenumber: [
        { type: 'required', message: this.getTranslate('FORMERRORS.required') },
        { type: 'minlength', message: this.getTranslate('FORMERRORS.minLength', '5') },
        { type: 'maxlength', message: this.getTranslate('FORMERRORS.maxLength', '9') },
        { type: 'pattern', message: this.getTranslate('FORMERRORS.phoneNumber') }
      ],
      password: [
        { type: 'required', message: this.getTranslate('FORMERRORS.required') },
        { type: 'minlength', message: this.getTranslate('FORMERRORS.minLength', '6') },
        { type: 'maxlength', message: this.getTranslate('FORMERRORS.maxLength', '30') },
        { type: 'pattern', message: this.getTranslate('FORMERRORS.password') }
      ],
      midText: [
        { type: 'required', message: this.getTranslate('FORMERRORS.required') },
        { type: 'minlength', message: this.getTranslate('FORMERRORS.minLength', '100') },
        { type: 'maxlength', message: this.getTranslate('FORMERRORS.maxLength', '1000') }
      ],
      inputRequired: [
        { type: 'required', message: this.getTranslate('FORMERRORS.required') }
      ],
      date: [
        { type: 'required', message: this.getTranslate('FORMERRORS.required') },
        { type: 'pattern', message: this.getTranslate('FORMERRORS.date') }
      ],
      passwordSell: [
        { type: 'required', message: this.getTranslate('FORMERRORS.required') },
        { type: 'minlength', message: this.getTranslate('FORMERRORS.minLength', '5') },
        { type: 'maxlength', message: this.getTranslate('FORMERRORS.maxLength', '20') },
        { type: 'pattern', message: this.getTranslate('FORMERRORS.passwordSell') }
      ],
    };

    return errorMessages;
  }

  // FILTER INPUTS ===================================================================
  filterData(key: string, value: string): string {
    var textInputs = ['firstname', 'lastname'];

    if (key.indexOf('date') >= 0) {
      return this.dateCtrl.getDateFromString(value);
    }
    else if (textInputs.includes(key)) {
      return this.convertFirstCharTotoUpper(value)
    } else {
      return value
    }
  }

  // STORE DATA ===================================================================
  storeData(dataObject: object) {
    for (let key in dataObject) {
      this.storageCtrl.set(key, dataObject[key])
    }
  }

  getStoreData(dataKey: string) {
    return this.storageCtrl.get(dataKey).then(data => {
      return data;
    });
  }
  // DELETE STORED DATA ===================================================================
  deleteData(keys: Array<string>) {
    keys.forEach(key => {
      this.storageCtrl.remove(key);
    });
  }

  sanitizer(dataObject: object) {
    const sanitizeObect: any = {};
    const text = ['firstname', 'lastname', 'gender'];
    const date = ['dateOfBirth'];
    const phoneNumber = ['phonenumber', 'countryDialCode'];

    for (let key in dataObject) {
      if (text.indexOf(key) >= 0) {
        sanitizeObect[key] = this.convertFirstCharTotoUpper(dataObject[key]);
        continue;
      }
      if (date.indexOf(key) >= 0) {
        sanitizeObect[key] = this.dateCtrl.getDateFromString(dataObject[key]);
        continue;
      }
      sanitizeObect[key] = dataObject[key];
    }
    return sanitizeObect;
  }

  getUserProfile(profileObject: any) {
    const profile = {
      firstname: '',
      lastname: '',
      phonenumber: '',
    };
    profile.firstname = this.convertFirstCharTotoUpper(profileObject.firstname);
    profile.lastname = this.convertFirstCharTotoUpper(profileObject.lastname);
    profile.phonenumber = profileObject.phonenumber;
    return profile;
  }

  isEqual(value: any, other: any) {

    // Get the value type
    const type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false;

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    // Compare two items
    let compare = function (item1, item2) {

      // Get the object type
      let itemType = Object.prototype.toString.call(item1);

      // If an object or array, compare recursively
      if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
        if (!this.isEqual(item1, item2)) return false;
      }

      // Otherwise, do a simple comparison
      else {

        // If the two items are not the same type, return false
        if (itemType !== Object.prototype.toString.call(item2)) return false;

        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (itemType === '[object Function]') {
          if (item1.toString() !== item2.toString()) return false;
        } else {
          if (item1 !== item2) return false;
        }

      }
    };

    // Compare properties
    if (type === '[object Array]') {
      for (var i = 0; i < valueLen; i++) {
        if (compare(value[i], other[i]) === false) return false;
      }
    } else {
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          if (compare(value[key], other[key]) === false) return false;
        }
      }
    }

    // If nothing failed, return true
    return true;

  };

  createStringCapitalNumbers(length: number) {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  stringToCapital(string: string) {
    return string.toUpperCase();
  }

  getSlideConfig() {
    return this.sliderConfig;
  }

  objectToArray(object: object, load: boolean = false) {
    let array = [];
    for (let key in object) {
      let item: any = {
        key: key,
        data: object[key]
      }
      if (load) {
        item.load = false;
        item.image = null;
      }
      array.push(item);
    }
    return array;
  }

  arrayToObject(data: any) {
    const products: any = {};
    data.forEach((item: any) => {
      products[item.key] = item.data;
    })
    return products;
  }


  makePostsData(data: any) {
    const posts = this.objectToArray(data);
    for (var i = 0; i < posts.length; i++) {
      if (posts[i].data.info.images) {
        const propertyImages = this.objectToArray(posts[i].data.info.images.property, true);
        posts[i].data.info.images.property = propertyImages;
        const epcImages = this.objectToArray(posts[i].data.info.images.epc, true);
        posts[i].data.info.images.epc = epcImages;
      } else {
        posts[i].data.info.images = false;
      }

      if (posts[i].data.info.purpose === 'Sale') {
        posts[i].data.info.disabledText = 'SOLD'
      } else if (posts[i].data.info.purpose === 'Rent') {
        posts[i].data.info.disabledText = 'RENTED';
      }

      posts[i].data.info.descriptionLines = 30;
      if (posts[i].data.info.description.split(/\r\n|\r|\n/).length > 30) {
        posts[i].data.info.descriptionLines = posts[i].data.info.description.split(/\r\n|\r|\n/).length + 20;
      }
      let descriptionFormated =    posts[i].data.info.description;
    
      descriptionFormated.replace(/\r?\n/, '');
    
      if (descriptionFormated.length > 100) {
        descriptionFormated = descriptionFormated.substring(0, 100) + '...';
      }
  
      if (descriptionFormated.length < 50) {
        descriptionFormated =  descriptionFormated.substring(0, 100) + '... Please click here to find out more';
      }
      posts[i].data.info.descriptionFormated = descriptionFormated;
    }
    return posts;
  }

  convertNumberToCurrency(value: number) {
    const formatter = new Intl.NumberFormat('en-UK', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0
    });
    return formatter.format(value);
  }

  sortPostsData(sortStr: string, data: any, isOrderDescending: boolean = false) {
    let sortA = 1;
    let sortB = -1;
    if (isOrderDescending) {
      sortA = -1;
      sortB = 1;
    }
    return data.sort(
       (a, b) => {
      if (a.data.info[sortStr] > b.data.info[sortStr]) {
        return sortA;
      }
      if (a.data.info[sortStr] < b.data.info[sortStr]) {
        return sortB;
      }
      return 0;
    });
  }

  filterPostsData(str: string, data: any, filters: Array<any> = null) {
    if (!filters) {
      filters = this.filtersOptions;
    }
    if (str !== '') {
      let dataToFilter = JSON.parse(JSON.stringify(data));
      let found = false;
      dataToFilter = dataToFilter.filter((post) => {
        let check = false;
        filters.forEach(filter => {
          let search = String(post.data.info[filter]).trim().toLocaleLowerCase().indexOf(str.toLowerCase().trim()) >= 0;
          if (filter === 'active') {
            search = String(post.data.info.package.active).trim().toLocaleLowerCase().indexOf(str.toLowerCase().trim()) >= 0;
          }
          if (filter === 'expired') {
            search = String(post.data.info.package.expired).trim().toLocaleLowerCase().indexOf(str.toLowerCase().trim()) >= 0;
          }
          if (search) {
            check = search;
          }
        });


        if (check) {
          found = check;
        }
        return check;
      });
      if (!found) {
        this.presentToast(`No result found for ${str}`);
      }
      return dataToFilter;
    }
  }

  salePrice(start: number) {
    let value: number = start;
    const listValues: Array<any> = [];
    const max = 15000000;

    listValues.push({
      value
    });
    if (start < max) {
      do {
        if (value < 250000) {
          value += 10000;
        } else if (value < 500000) {
          value += 25000;
        } else if (value < 1000000) {
          value += 50000;
        } else if (value < 2500000) {
          value += 100000;
        } else if (value < 5000000) {
          value += 250000;
        } else if (value < 10000000) {
          value += 500000;
        } else if (value < max) {
          value += 2500000;
        }
        const valueObject = {
          value
        };
        listValues.push(valueObject);
      } while (value < max);
      return listValues;
    }
    this.presentAlert('start value is bigger than max');
    return [];
  }

  rentPriceWeek(start: number) {
    let value: number = start;
    const listValues: Array<any> = [];
    const max = 7500;

    listValues.push({
      value: value
    });
    if (start < max) {
      do {
        if (value < 500) {
          value += 25;
        } else if (value < 1000) {
          value += 50;
        } else if (value < 2000) {
          value += 250;
        } else if (value < 5000) {
          value += 500;
        } else if (value < 7500) {
          value += 2500;
        }
        const valueObject = {
          value: value
        };
        listValues.push(valueObject);
      } while (value < max);
      return listValues;
    }
    this.presentAlert('start value is bigger than max')
    return [];
  }

  rentPriceMonths(start: number) {
    let value: number = start;
    const listValues: Array<any> = [];
    const  max = 25000;

    listValues.push({
      value: value
    });
    if (start < max) {
      do {
        if (value < 1000) {
          value += 100;
        } else if (value < 5000) {
          value += 250;
        } else if (value < 10000) {
          value += 500;
        } else if (value < 20000) {
          value += 2500;
        } else if (value < 25000) {
          value += 5000;
        }
        const valueObject = {
          value: value
        };
        listValues.push(valueObject);
      } while (value < max);
      return listValues;
    }
    this.presentAlert('start value is bigger than max')
    return [];
  }

  numberOfBeds(start: number) {
    let value: number = start;
    let listValues: Array<any> = []
    let max: number = 10
    if (start <= max) {
      for (let i = value; i <= max; i++) {
        let valueObject = {
          value: i,
          text: this.numberOfBedsText(i)
        }
        listValues.push(valueObject)
      }
      return listValues
    }
    this.presentAlert('start value is bigger than max')
    return []
  }

  numberOfBedsText(number: number) {
    // if (number == -1) {
    //   return 'Any'
    // } else 
    if (number == 0) {
      return 'Studio'
    } else if ((number > 0) && (number < 10)) {
      return number;
    } else if (number == 10) {
      return number + '+';
    } else {
      this.presentAlert('Error creating number of beds text')
    }
  }

  createButtonSelectObject(data: Array<any>, addAll: boolean = true) {
    let buttonArray = []
    if (addAll) {
      buttonArray.push({ value: 'all', active: 'clear' })
    }
    data.forEach(item => {
      buttonArray.push({ value: item, active: 'solid' })
    })
    return buttonArray;
  }

  createPagerObject(posts: Array<any>) {
    let pager: Array<any> = [];
    let i: number = 1;
    let itemsPerPage = 5;
    let itemsUsed = 1;
    let postsForPage: Array<any> = [];
    posts.forEach(post => {
      post.mainIndex = i - 1;
      postsForPage.push(post);
      if (((i % itemsPerPage) == 0) || (i == posts.length)) {

        let page = {
          number: pager.length + 1,
          pageItems: `${itemsUsed}-${i}`,
          posts: JSON.parse(JSON.stringify(postsForPage)),
          fill: 'outline'
        }
        pager.push(page);
        itemsUsed = i + 1;
        postsForPage = [];
      }
      i++;
    })
    return pager;
  }

  copyToClipboard(str: string) {
    Clipboard.copy(str);
  }

  getPostForm() {

    return this.formBuilder.group({
      purpose: ['', Validators.compose([
        Validators.required, Validators.pattern('[a-zA-Z]*'),
        Validators.minLength(2),
        Validators.maxLength(30)
      ])],
      type: ['', Validators.compose([
        Validators.required, Validators.pattern('[a-zA-Z -]*'),
        Validators.minLength(2),
        Validators.maxLength(30)
      ])],
      // propertyType: ['', Validators.compose([
      //   Validators.required,
      //   Validators.minLength(2),
      //   Validators.maxLength(30)
      // ])],
      title: ['', Validators.compose([
        Validators.required, Validators.pattern('[a-zA-Z0-9 -]*'),
        Validators.minLength(2),
        Validators.maxLength(30)
      ])],
      availableFrom: ['', Validators.compose([
        Validators.required])],
      address: ['', Validators.compose([
        Validators.maxLength(200),
        Validators.pattern("[a-zA-Z0-9 ,-.\"'`]*"),
        Validators.required])],
      towncity: ['', Validators.compose([
        Validators.maxLength(200),
        Validators.pattern('[a-zA-Z0-9 ,-.]*'),
        Validators.required])],
      county: ['', Validators.compose([
        Validators.maxLength(200),
        Validators.pattern('[a-zA-Z0-9 ,-.]*'),
        Validators.required])],
      postcode: ['', Validators.compose([
        Validators.maxLength(15),
        Validators.pattern('[a-zA-Z0-9 -]*'),
        Validators.required])],
      price: ['', Validators.compose([
        Validators.maxLength(11),
        Validators.pattern('[0-9,.£]*'),
        Validators.min(1),
        Validators.required])],
      priceType: ['', Validators.compose([
        Validators.required])],
      // price2: ['', Validators.compose([
      //   Validators.maxLength(6),
      //   Validators.pattern('[0-9]*'),
      //   Validators.min(1),
      //   Validators.required])],
      // priceType2: ['', Validators.compose([
      //   Validators.required])],
      numberOfBeds: ['', Validators.compose([
        Validators.maxLength(15),
        Validators.pattern('[0-9]*'),
        Validators.min(0),
        Validators.required])],
      numberOfBaths: ['', Validators.compose([
        Validators.maxLength(15),
        Validators.pattern('[0-9]*'),
        Validators.min(1),
        Validators.required])],
      description: ['', Validators.compose([
        Validators.maxLength(5000),
        // Validators.pattern('[a-zA-Z0-9 .!?]*'),
        Validators.required])],
      // propertyAge: ['', Validators.compose([
      //   Validators.maxLength(500),
      //   Validators.pattern('[0-9+-]*')
      // ])],
      constructionDate: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(4),
        Validators.min(1000),
        Validators.max(2100),
        Validators.pattern('[0-9]*')
      ])],
      internalArea: ['', Validators.compose([
        Validators.pattern('[0-9]*'),
        Validators.min(50),
        Validators.max(999999),
        Validators.required])],
      furnished: [false, Validators.compose([
        Validators.required])],
      finishQuality: [false, Validators.compose([
          Validators.required])],
      outdoorSpace: [false, Validators.compose([
          Validators.required])],
      parking: ['', Validators.compose([
        Validators.required])],
      // offStreetParking: ['', Validators.compose([
      //     Validators.required])],
      email: ['', Validators.compose([
        Validators.required, Validators.pattern('.*\@.*\..*'),
        Validators.minLength(6),
        Validators.maxLength(50)
      ])],
      phonenumber: ['', Validators.compose([
        Validators.required//, PhoneValidator.validCountryPhone(this.registerForm.controls.country)
        , Validators.minLength(7),
        Validators.maxLength(15)
      ])],
      lat: ['', Validators.compose([
        Validators.maxLength(25),
        Validators.pattern('[0-9,-.]*')])],
      lng: ['', Validators.compose([
        Validators.maxLength(25),
        Validators.pattern('[0-9,-.]*')])]
    });
  }

}
