import { IsLoggedInGuard } from './guards/auth/is-logged-in.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
  { path: 'my-properties', canActivate: [IsLoggedInGuard], loadChildren: './pages/my-properties/my-properties.module#MyPropertiesPageModule'},
  { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule' },
  { path: 'termsandconditions', loadChildren: './pages/termsandconditions/termsandconditions.module#TermsandconditionsPageModule' },
  { path: 'rentals', loadChildren: './pages/rentals/rentals.module#RentalsPageModule' },
  { path: 'rentals/:search', loadChildren: './pages/rentals/rentals.module#RentalsPageModule' },
  { path: 'sales', loadChildren: './pages/sales/sales.module#SalesPageModule' },
  { path: 'sales/:search', loadChildren: './pages/sales/sales.module#SalesPageModule' },
  { path: 'login-register', loadChildren: './pages/login-register/login-register.module#LoginRegisterPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
