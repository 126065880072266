import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  data: any;

  priceRent: Array<number> = []
  priceRentMin: number = 50;
  priceRentMax: number = 5000;
  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit() {
    console.log(this.data);

    // let i = this.priceRentMin;
    // do {
    //   this.priceRent.push(i)
    //   i = i + this.priceRentMin;
    // } while (this.priceRentMin <= this.priceRentMax);

    console.log(this.priceRent)

  }
  itemClick(value: any) {
    this.popoverCtrl.dismiss(value);
  }

}
