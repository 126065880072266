export const environment = {
  production: true,
  stripeKey: 'pk_live_XUsvfpxh19ArNU8SGDlcRoJQ00aj7SaUSo',
  firebase: {
    apiKey: "AIzaSyCCvX4n4c5mbqnJKIbFkDwXiMwHbMryves",
    authDomain: "loyalhomes-d5cdc.firebaseapp.com",
    databaseURL: "https://loyalhomes-d5cdc.firebaseio.com",
    projectId: "loyalhomes-d5cdc",
    storageBucket: "loyalhomes-d5cdc.appspot.com",
    messagingSenderId: "992266913371",
    appId: "1:992266913371:web:24ee06248a752f7bd7e17e",
    measurementId: "G-GFYZN76WLW"
  },
  propertyDataKey: 'SYNWZLOHB4',
  googleMapsApiKey: 'AIzaSyBVvz1Goep4j6E0kKsH6zdYKGqulPF1eQI'
};
