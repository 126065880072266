import { environment } from './../../../environments/environment.prod';
import { Component, OnInit, OnDestroy } from '@angular/core';
declare var Stripe;
import { AuthService } from 'src/app/services/auth/auth.service';


@Component({
  selector: 'app-subscription-payment',
  templateUrl: './subscription-payment.component.html',
  styleUrls: ['./subscription-payment.component.scss'],
})
export class SubscriptionPaymentComponent implements OnInit, OnDestroy {

  stripe = Stripe(environment.stripeKey);
  card: any;
  data: any;
  package: any;
  postsPayed = false;
  paymentLoading = false;

  private isClick = true;
  private events: Array<string> = [];

  constructor(public afAuth: AuthService) {

    this.afAuth.helpers.eventsCtrl.subscribe('paymentResponse', (data) => {
      console.log(data);
      this.afAuth.helpers.hideLoading();
      if (data) {
        if (data.error) {
          this.paymentLoading = false;
        }
        if (data.paymentComplete) {
          this.postsPayed = true;
          this.closeModal();
        }
      }
    });
    this.events.push('paymentResponse');
  }

  ngOnInit() {
    console.log(this.data);
    this.setupStripe();
  }

  ngOnDestroy() {
    console.log('On Destroy');
    this.events.forEach(event => {
      console.log(event);
      this.afAuth.helpers.eventsCtrl.unsubscribe(event);
    });
  }

  closeModal() {
    this.afAuth.helpers.modalCtrl.dismiss({ postPayed: this.postsPayed });
  }

  setupStripe() {

    const elements = this.stripe.elements();
    const style = {
      base: {
        color: '#32325d',
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.card = elements.create('card', { style });
    console.log(this.card);
    this.card.mount('#card-element');

    this.card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = null;
      }
    });

    const form = document.getElementById('payment-form');
    form.addEventListener('submit', event => {
      event.preventDefault();
      console.log(event);

      this.stripe.createSource(this.card).then(result => {
        if (result.error) {
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          console.log(result);
          this.makePayment(result.source.id);
        }
      });
    });
  }

  makePayment(tokenId: string) {
    if (this.isClick) {
      this.isClick = false;
      this.paymentLoading = true;
      this.afAuth.helpers.presentLoading();
      const paymentObject = {
        source: tokenId,
        purpose: this.data.purpose,
        postId: this.data.postId,
        packageName: this.data.package.name
      };
      this.afAuth.callCloudFunction('payWithStripe', paymentObject, 'paymentResponse');
    }
  }


}
