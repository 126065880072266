import { Component, OnInit, OnDestroy, ViewChild, HostListener, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IonContent } from '@ionic/angular';
import { environment } from 'src/environments/environment';

declare var google: any;

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit, OnDestroy {

  map: any;

  @ViewChild('map', {read: ElementRef, static: false}) mapRef: ElementRef;

  @ViewChild(IonContent) content: IonContent;

  post: any;
  similarPosts: Array<any>;
  data: any;
  postId: string;
  private events: Array<string> = [];
  getPostDataCheck = true;
  spinner = true;
  p: any;

  mapOrImage = false;

  mapImageUrl: string = `https://maps.googleapis.com/maps/api/staticmap?center=UK,CA&zoom=14&size=2000x600&markers=%7Ccolor:0xc59f00%7Clabel:C%7CUK%22&key=${environment.googleMapsApiKey}`

  segment = 'details';

  showContacts = true;

  scrHeight: any;
  scrWidth: any;
  smallSceen = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;

    this.smallSceen = false;
    if (window.innerWidth < 990) {
      this.smallSceen = true;
    }
  }

  constructor(private afAuth: AuthService) {

    this.afAuth.helpers.eventsCtrl.subscribe('postDetails', (postData) => {
      if (postData) {
        this.post = postData;
      } else {
        this.afAuth.helpers.rootPage('');
      }
    });
    this.events.push('postDetails');

    this.afAuth.helpers.eventsCtrl.subscribe('getPostData', (data) => {
      if (data) {
        const postObject = {};
        postObject[this.data.postId] = data;
        this.post = this.afAuth.helpers.makePostsData(postObject)[0];
        this.searchProperties();
        this.runPostFunctions();
      } else {
        if (this.getPostDataCheck) {
          this.getPostDataCheck = false;
          this.getPostData(this.data.postId, 'sale');
        } else {
          this.afAuth.helpers.presentToast('Post not found !! <br><br>Property post may have been deleted');
          this.closeModal();
        }
      }
      this.spinner = false;
    });
    this.events.push('getPostData');


    this.afAuth.helpers.eventsCtrl.subscribe('getPosts_PostsDetails', (data) => {
      if (data) {
        this.similarPosts = this.afAuth.helpers.makePostsData(data.posts);
      }
      this.spinner = false;
    });
    this.events.push('getPosts_PostsDetails');

    this.afAuth.helpers.eventsCtrl.subscribe('showContacts', () => {
      this.showContacts = false;
      this.spinner = false;
    });
    this.events.push('showContacts');

    this.getScreenSize();
  }

 

  ScrollToTop() {
    this.content.scrollToTop(1500);
  }

  ionViewDidEnter() {
    if(!this.mapOrImage){
      this.showMap();
    }
  }


  ngOnInit() {

    if (this.data) {

      if (this.data.postId) {
        this.getPostData(this.data.postId.replace(/"/g, ''), this.data.purpose);
      }
      if (this.data.post) {
        this.post = this.data.post;
        this.similarPosts = this.data.similarPosts;
        this.runPostFunctions();
      }

    }
  }

  runPostFunctions() {
    if(this.post.data.info.lat && this.post.data.info.lng){
      this.mapOrImage = false
    }else {
      setTimeout(() => {
        this.setImageURL();
      }, 2000);
      this.mapOrImage = true
    }
    this.addPostView();
  }

  showMap() {
    const location = new google.maps.LatLng(this.post.data.info.lat, this.post.data.info.lng);
    const options = {
      center: location,
      zoom: 15,
      // disableDefaultUI: true
    };
    this.map = new google.maps.Map(this.mapRef.nativeElement, options);


    new google.maps.Marker({
      position: location,
      map:this.map,
      title: "Hello World!",
    });
  }



  setImageURL() {
    const postAddressPostCode: string = String(this.post.data.info.postcode).trim().replace(/ /g, '');
    const mapImage = document.getElementById('map');
    if (postAddressPostCode) {
      this.mapImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${postAddressPostCode}%209ny%203dl,CA&zoom=14&size=${this.scrWidth}x300&markers=%7Ccolor:0xc59f00%7Clabel:C%7C${postAddressPostCode}%209ny%22&key=${environment.googleMapsApiKey}`;
      try {
        mapImage.setAttribute('src', this.mapImageUrl);
        } catch (error) {
      }
    }

  }

  addPostView() {
    const viewObject = {
      postId: this.post.key,
      id: this.post.data.id,
      type: this.post.data.info.purpose.toLowerCase(),
    };
    this.afAuth.callCloudFunction('addPostViewCount', viewObject);
  }

  ngOnDestroy() {
    this.events.forEach(event => {
      this.afAuth.helpers.eventsCtrl.unsubscribe(event);
    });
  }

  closeModal(returnData = null) {
    this.afAuth.helpers.modalCtrl.dismiss(returnData);
  }

  getPostData(postId: string, purpose: string) {
    this.spinner = true;
    const request = {
      postId: postId,
      purpose: purpose
    };
    this.afAuth.callCloudFunction('getPost', request, 'getPostData');
  }

  seeContact() {
    this.afAuth.helpers.presentAlert(this.post.data.info.phonenumber);
  }

  segmentChanged(event: any) {
    if (event.detail) {
      this.segment = event.detail.value;
    } else {
      this.segment = event;
    }
  }

  searchProperties() {
    this.spinner = true;

    const search = {
      maxBeds: this.post.data.info.numberOfBeds,
      maxPrice: this.post.data.info.price,
      minBeds: 0,
      minPrice: 0,
      purpose: this.post.data.info.purpose,
      priceType: this.post.data.info.priceType,
      text: this.post.data.info.towncity,
      type: this.post.data.info.type,
      end: '',
      lastpostId: this.post.key,
      limit: 3
    };
    this.afAuth.callCloudFunction('getPosts', search, 'getPosts_PostsDetails');
  }

  isNew(date: number) {
    return this.afAuth.helpers.dateCtrl.getMoment().subtract(12, 'days').isBefore(date);
  }

  viewPostDetails(index) {
    const temPost = JSON.parse(JSON.stringify(this.post));
    this.post = this.similarPosts[index];
    this.similarPosts[index] = temPost;
    this.showContacts = true;
    this.ScrollToTop();
    this.runPostFunctions();
  }

  convertToCurrency(value: any) {
    return this.afAuth.helpers.convertNumberToCurrency(value);
  }

  convertToDate(date: any) {
    return this.afAuth.helpers.dateCtrl.getDateFromTimestamp(date, 'LL');
  }

  convertToFirstCharToCapital(str: string) {
    return this.afAuth.helpers.convertFirstCharTotoUpper(str);
  }

  viewContact() {
    this.afAuth.helpers.presentAlertConfirm('By clicking on okay you agree with our <a>Terms and Conditions</a> as well as our <a>Privacy Policy</a>.', 'showContacts');
    // this.afAuth.helpers.presentAlertConfirm('By clicking on okay you agree with our <a href="/about?section=termsandconditions" target="_blank">Terms and Conditions</a> as well as our <a href="/about?section=privacyandpolicy" target="_blank">Privacy Policy</a>.', 'showContacts')
  }

  sharePost() {
    this.afAuth.helpers.copyToClipboard(this.post.data.info.url);
    this.afAuth.helpers.presentToast('Post url copied to clipboard !!');
  }

}

