import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { CloudFunctionsService } from '../../services/cloudFunctions/cloud-functions.service';
import { HelpersService } from '../../services/helpers/helpers.service';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLoggedInStream: Observable<boolean>;
  // public currentUserObject: any;
  public currentUserDataObject: any;
  private milisecondsToUpdate = 864000;
  public emailVerification = false;


  constructor(public afAuth: AngularFireAuth,
    private afDatabase: AngularFireDatabase,
    private cldFunc: CloudFunctionsService,
    public helpers: HelpersService) {

    this.isLoggedInStream = this.afAuth.authState.pipe(
      map<firebase.User, boolean>((user: firebase.User) => {
        return user != null;
      })
    );
    this.setUserVariables();
  }
  // SET PUBLIC USER VARIABLES ===================================================================

  setUserVariables() {
    this.getCurrentUser().onAuthStateChanged(authObserver => {
      let user = false;
      if (authObserver) {
        user = true;
        this.emailVerification = authObserver.emailVerified;
      }
      this.helpers.eventsCtrl.publish('loginUser', user);
    });
  }

  // GET CURRENTUSER =======================================================
  getCurrentUser() {
    return firebase.auth();
  }

  // SEND VERIFICATION EMAIL =======================================================
  async resendEmailVerification() {
    const currentUser = await this.getCurrentUser();
    currentUser.onAuthStateChanged(authObserver => {
      if (authObserver) {
        authObserver.sendEmailVerification().then(() => {
          this.helpers.presentToast(`Verification Email was sent to ${authObserver.email}`);
        }).catch(error => {
          this.helpers.presentAlert(`Could not send Verification Email to ${authObserver.email} \n please try again later`);
        });
      } else {
        this.helpers.presentAlert(`Could not send Verification Email to ${authObserver.email} \n please try again later`);
      }
    });
  }

  // LOGIN =======================================================
  async login(userEmail: string, userPassword: string, closeEvent: string = null): Promise<any> {
    this.helpers.presentLoading();
    await this.afAuth.auth.signInWithEmailAndPassword(userEmail, userPassword)
      .then(userData => {
        // this.setUserVariables(true, 'checkLogin');
        if (closeEvent) {
          this.helpers.eventsCtrl.publish(closeEvent);
        } else {
          this.helpers.eventsCtrl.publish('resetForms', userEmail);
          this.helpers.presentToast(`Try again`);
        }
      }).catch(error => {
        this.helpers.presentAlert(error.message);
      }).finally(() => {
        this.helpers.hideLoading();
      });

  }

  // LOGOUT =======================================================
  async logout(goToHome: boolean = false, eventPublish: string = null): Promise<any> {
    await this.afAuth.auth.signOut().then(authData => {
      if (goToHome) {
        this.helpers.rootPage('/home');
        this.helpers.navCtrl.navigateRoot('/home');
      }
      if (eventPublish) {
        this.helpers.eventsCtrl.publish('loginUser');
      }
    });
  }

  // REGISTER =======================================================
  async registerUser(userNewData: any, eventPublish: string): Promise<any> {
    this.helpers.presentLoading();
    await this.afAuth.auth.createUserWithEmailAndPassword(userNewData.email, userNewData.password)
      .then(userData => {
        //Update user profile data
        userData.user.updateProfile({
          displayName: this.helpers.convertFirstCharTotoUpper(userNewData.firstname) +
            ' ' + this.helpers.convertFirstCharTotoUpper(userNewData.lastname),
          photoURL: null
        }).then(() => {
          this.helpers.rootPage('/home');
        }).catch((error) => {
        });

        // setTimeout(() => {
        //   const data = {
        //     firstname: userNewData.firstname,
        //     lastname: userNewData.lastname,
        //   };
        //   //Update user info data
        //   // this.updateUserInfo(userData.user.uid, data, 'info');
        // }, 5000);
        this.helpers.eventsCtrl.publish(eventPublish);
      }).catch(error => {
        this.helpers.presentAlert('Info', error.message);
        const data = {
          error: true
        };
        this.helpers.eventsCtrl.publish(eventPublish, data);
        this.helpers.hideLoading();
      }).finally(() => {
        this.helpers.hideLoading();
      });
  }


  // DELETE USER ====================================================
  async deleteUser() {
    this.getCurrentUser().currentUser.delete().then(() => {
      // User deleted.
      this.helpers.eventsCtrl.publish('accountDeleted');
    }).catch((error) => {
      // An error happened.
      if (error.code === 'auth/requires-recent-login') {
        this.signInAlert(error.message);
      } else {
        this.helpers.presentAlert(error);
      }
    });
  }


  // RESET PASSWORD =======================================================
  resetPassword(email: string): Promise<any> {
    return this.getCurrentUser().sendPasswordResetEmail(email);
  }


  // CREATE DATA MAIN FUNCTION ===================================================================
  createData(ref: string, data: object, createMessage: string, eventPublish: string = null) {
    firebase.database().ref(ref).push(data)
      .then((response) => {
        if (eventPublish) {
          this.helpers.eventsCtrl.publish(eventPublish, response.key);
        }
        this.helpers.presentToast(createMessage);
      }).catch(error => {
        this.helpers.presentAlert(`Error creating data. Please try again` + error);
        if (eventPublish) {
          this.helpers.eventsCtrl.publish(eventPublish, false);
        }
      });
  }

  // CREATE DATA MAIN FUNCTION ===================================================================
  deleteData(ref: string) {
    firebase.database().ref(ref).remove()
      .catch(error => {
        this.helpers.presentAlert(`Error deleting data. Please try again` + error);
      });
  }

  // GET DATA MAIN FUNCTION ===================================================================
  getData(ref: string, eventPublish: string) {
    const stockroomData = this.afDatabase.database.ref(ref);
    stockroomData.once('value', snapshot => {
      const data = snapshot.val();
      this.helpers.eventsCtrl.publish(eventPublish, data);
    }).catch((error) => {
      this.helpers.presentAlert(error.message);
      if (eventPublish) {
        this.helpers.eventsCtrl.publish(eventPublish, false);
      }
    });
  }

  // QUERY DATA MAIN FUNCTION ===================================================================
  queryData(ref: string) {
    return this.afDatabase.database.ref(ref);
  }

  // CALL CLOUD FUNCTION MAIN FUNCTION ===================================================================
  callCloudFunction(functionName: string, dataToFunction: any, eventPublish: string = null) {
    const cloudFunction = firebase.functions().httpsCallable(functionName);
    cloudFunction(dataToFunction).then(response => {
      if (eventPublish) {
        this.helpers.eventsCtrl.publish(eventPublish, response.data);
      }
    }).catch(error => {
      if (error.code === 'failed-precondition') {
        this.signInAlert(error.message);
      } else {
      }
      const errorData = true;
      this.helpers.eventsCtrl.publish(eventPublish, { error: true });
    });
  }

  // UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES 
  // UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES 
  // UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES 
  // UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES 
  // UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES  UPDATES 


  // UPDATE DATA MAIN FUNCTION ===================================================================
  updateData(ref: string, child: string, updates: any, updateMessage: string, eventPublish: string = null) {
    firebase.database().ref(ref).child(child).update(updates).then(() => {
      if (eventPublish) {
        this.helpers.eventsCtrl.publish(eventPublish, true);
      }
      this.helpers.presentToast(updateMessage);
    }).catch(error => {
      this.helpers.presentAlert(`Error updating data. Please try again later` + error);
      if (eventPublish) {
        this.helpers.eventsCtrl.publish(eventPublish, false);
      }
    });
  }

  updateData2(ref: string, child: string, updates: any, updateMessage: string, eventPublish: string = null) {
    firebase.database().ref(ref).update(updates).then(() => {
      if (eventPublish) {
        this.helpers.eventsCtrl.publish(eventPublish, true);
      }
      this.helpers.presentToast(updateMessage);
    }).catch(error => {
      this.helpers.presentAlert(`Error updating data. Please try again later` + error);
      if (eventPublish) {
        this.helpers.eventsCtrl.publish(eventPublish, false);
      }
    });
  }

  // UPDATE USER DETAILS PROFILE =======================================================
  async updateUserDetails(userNewData: any, eventPublish: string = null): Promise<any> {
    const currentUser = await this.getCurrentUser().currentUser;
    currentUser.updateProfile({
      displayName: this.helpers.convertFirstCharTotoUpper(userNewData.firstname) +
        ' ' + this.helpers.convertFirstCharTotoUpper(userNewData.lastname),
      photoURL: null
    }).then(() => {
      const ref = `users/${currentUser.uid}`;
      const child = `info`;
      return this.updateData(ref, child, userNewData, 'User details updated !!', eventPublish);
    }).catch(error => {
      this.helpers.presentAlert(error.message);
      this.helpers.eventsCtrl.publish(eventPublish, false);
    });
  }

  // UPDATE USER INFO =======================================================
  updateUserInfo(uid: string, infoObject: object, child: string) {
    const ref = `users/${uid}/`;
    this.updateData(ref, child, infoObject, 'Info updated', 'changeDisplayName');
  }

  // UPDATE USER EMAIL =======================================================
  async updateUserEmail(email: string, eventPublish: string = null) {
    // this.helpers.presentLoading();
    const currentUser = await this.getCurrentUser().currentUser;
    currentUser.updateEmail(email)
      .then(() => {
        this.helpers.presentToast('Email updated');
        this.helpers.eventsCtrl.publish(eventPublish);
      }).catch(error => {
        if (error.code === 'auth/requires-recent-login') {
          this.signInAlert(error.message);
        } else {
          this.helpers.presentAlert(error);
        }
        this.helpers.eventsCtrl.publish('resetForms');
      });
  }

  // UPDATE USER PASSWORD =======================================================
  async updateUserPassword(newPassword: string) {
    const currentUser = await this.getCurrentUser().currentUser;
    currentUser.updatePassword(newPassword)
      .then(() => {
        this.helpers.presentToast(`Password Updated`);
      }).catch(error => {
        if (error.code === 'auth/requires-recent-login') {
          this.signInAlert(error.message);
        } else {
          this.helpers.presentAlert(error);
        }
      });
  }

  // ALERT SIGN IN ===================================================================
  async signInAlert(message: string) {
    const alert = await this.helpers.alertCtrl.create({
      message: message + '<br> Please Sign in and try again',
      inputs: [
        {
          name: 'email',
          placeholder: 'Email'
        },
        {
          name: 'password',
          placeholder: 'Password',
          type: 'password'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: 'Login',
          handler: data => {
            if (data) {
              // logged in!
              this.login(data.email, data.password).then(() => {
              });
            } else {
              // invalid login
              return false;
            }
          }
        }
      ]
    });
    await alert.present();
  }

  // UPLOAD IMAGES =======================================================
  uploadImages(images: Array<any>, index: number, ref: string, name: string, progressEvent: string, lastEvent: string = null) {
    let last: string = null;
    if (index === images.length) {
      last = lastEvent;
    }
    const progress = {
      publish: progressEvent,
      index: index
    };
    const location = `${ref}/`;
    const time = this.helpers.dateCtrl.getMoment().valueOf();
    const imageName = `${name}_${time}.jpg`;

    this.uploadImageToFirebaseStorage(images[index - 1], location, imageName).then(() => {
      this.helpers.eventsCtrl.publish(progressEvent, index, true);
    }).catch(error => {
      this.helpers.eventsCtrl.publish(progressEvent, index, false);
    }).finally(() => {
      index++;
      if (index <= images.length) {
        return this.uploadImages(images, index, ref, name, progressEvent, lastEvent);
      } else {
        if (lastEvent) {
          this.helpers.eventsCtrl.publish(last);
        }
        return;
      }
    });
  }

  // UPLOAD IMAGE FUNCTION ===================================================================
  uploadImageToFirebaseStorage(imageBlobInfo: any, location: string, name: string) {
    return new Promise((resolve, reject) => {
      const fileRef = firebase.storage().ref(location + name);
      const uploadTask = fileRef.putString(imageBlobInfo, 'data_url');
      uploadTask.on(
        'state_changed',
        (snap: any) => {
          // console.log(
          //   'progress ' +
          //   (snap.bytesTransferred / snap.totalBytes) * 100
          // );
        },
        error => {
          reject(error);
        },
        () => {
          // completion...
          resolve(uploadTask.snapshot);
        }
      );
    });
  }



}
