import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.page.html',
  styleUrls: ['./login-register.page.scss'],
})
export class LoginRegisterPage implements OnInit, OnDestroy {

  loginForm: FormGroup;
  registerForm: FormGroup;
  resetPasswordForm: FormGroup;
  errorMessages: any;
  private isClick = true;
  private events: Array<string> = [];
  option = 'Login';
  title: string = this.option;
  private resetCheck = false;
  registering = false;

  constructor(
    private formBuilder: FormBuilder,
    private afAuth: AuthService
  ) {
    this.errorMessages = this.afAuth.helpers.getErrorMessages();

    this.loginForm = formBuilder.group({
      email: ['', Validators.compose([
        Validators.required, Validators.pattern(".*\@.*\..*"),
        Validators.minLength(6),
        Validators.maxLength(50)
      ])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(50)
      ])]
    });

    this.registerForm = formBuilder.group({
      firstname: ['', Validators.compose([
        Validators.required, Validators.pattern("[a-zA-Z]*"),
        Validators.minLength(2),
        Validators.maxLength(30)
      ])],
      lastname: ['', Validators.compose([
        Validators.required, Validators.pattern("[a-zA-Z]*"),
        Validators.minLength(2),
        Validators.maxLength(30)
      ])],
      email: ['', Validators.compose([
        Validators.required, Validators.pattern(".*\@.*\..*"),
        Validators.minLength(6),
        Validators.maxLength(50)
      ])],
      // phonenumber: ['', Validators.compose([
      //   Validators.required//, PhoneValidator.validCountryPhone(this.registerForm.controls.country)
      //   , Validators.minLength(7),
      //   Validators.maxLength(15)
      // ])],
      password: ['', Validators.compose([
        Validators.required, Validators.pattern("[a-zA-Z0-9]*"),
        Validators.minLength(6),
        Validators.maxLength(30)
      ])],
      confirmPassword: ['', Validators.compose([
        Validators.required, Validators.pattern("[a-zA-Z0-9]*"),
        Validators.minLength(6),
        Validators.maxLength(30)
      ])],
      termsAndConditions: ['false', Validators.requiredTrue]
    });

    this.resetPasswordForm = formBuilder.group({
      email: ['', Validators.compose([
        Validators.required, Validators.pattern(".*\@.*\..*"),
        Validators.minLength(6),
        Validators.maxLength(50)
      ])],
    });

    this.afAuth.helpers.eventsCtrl.subscribe('closeLoginRegister', (data) => {
      if (data) {
        if (data.error) {
          this.registering = false;
          return;
        }
      }
      this.changeOption('Login');
      this.close();
    });
    this.events.push('closeLoginRegister');

    this.afAuth.helpers.eventsCtrl.subscribe('sendResetPasswordLink', (data) => {
      if (data.emailSent) {
        this.resetCheck = true;
      }
    });
    this.events.push('sendResetPasswordLink');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.events.forEach(event => {
      this.afAuth.helpers.eventsCtrl.unsubscribe(event);
    });
  }


  changeOption(type: string) {
    this.option = type;
    this.title = type;
  }

  close() {
    if (this.option !== 'Login') {
      this.changeOption('Login');
    } else {
      this.afAuth.helpers.modalCtrl.dismiss();
    }
  }

  async login() {
    if (this.isClick) {
      const email: string = this.loginForm.value.email;
      const password: string = this.loginForm.value.password;

      await this.afAuth.login(email, password, 'closeLoginRegister').then(() => {
        this.afAuth.callCloudFunction('sendWelcomeEmail', null);
      });
    }
  }

  async register() {
    const sanitizeData = this.afAuth.helpers.sanitizer(this.registerForm.value);
    if (this.isClick) {
      this.registering = true;
      const password: string = this.registerForm.value.password;
      const confirmPassword: string = this.registerForm.value.confirmPassword;
      if (password !== confirmPassword) {
        return this.afAuth.helpers.presentAlert('Passwords do not match');
      }
      await this.afAuth.registerUser(sanitizeData, 'closeLoginRegister');
    }
  }

  async resetPassword() {
    const data = {
      email: this.resetPasswordForm.value.email
    };
    this.afAuth.callCloudFunction('sendResetPasswordLink', data, 'sendResetPasswordLink');
  }
}
