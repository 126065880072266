import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  data: any;

  resetPasswordForm: FormGroup;
  errorMessages: any;
  authError = false;
  errorMessage = '';
  resetPasswordComplete = false;
  verifyEmailComplete = false;

  title = 'User Management';
  newPassword: any;
  segment: string;
  email: string;
  mainSpinner = false;
  spinner = false;

  constructor(public afAuth: AuthService, private formBuilder: FormBuilder) {
    this.errorMessages = this.afAuth.helpers.getErrorMessages();


  }

  ngOnInit() {
    console.log(this.data);
    this.setData(this.data);
  }

  closeModal() {
    this.afAuth.helpers.modalCtrl.dismiss();
  }

  setData(data: any) {
    if (data) {
      this.afAuth.logout().then(() => {
        if (this.data.mode === 'resetPassword') {
          this.title = 'Reset password';
          this.mainSpinner = true;
          this.afAuth.afAuth.auth.verifyPasswordResetCode(this.data.oobCode).then((email) => {
            this.mainSpinner = false;
            this.email = email;
            this.resetPasswordForm = this.formBuilder.group({
              newPassword: ['', Validators.compose([
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(50)
              ])]
            });
            this.segment = this.data.mode;
          }).catch(() => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            this.mainSpinner = false;
            this.authError = true;
            this.errorMessage = 'Invalid or expired action code. Try to reset the password again.';
          });
        }

        if (this.data.mode === 'verifyEmail') {
          this.title = 'Verify Email';
          this.mainSpinner = true;
          this.segment = this.data.mode;

          this.afAuth.afAuth.auth.applyActionCode(this.data.oobCode).then((resp) => {
            this.mainSpinner = false;
            console.log(resp);
            this.verifyEmailComplete = true;
          }).catch(() => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            this.mainSpinner = false;
            this.authError = true;
            this.errorMessage = 'Invalid or expired action code. Try to reset the password again.';
          });
        }
      });
    }

  }

  resetPassword() {
    this.spinner = true;
    this.handleResetPassword(this.afAuth.afAuth.auth, this.data.oobCode);
  }

  handleResetPassword(auth, actionCode) {
    // parameter.
    // var accountEmail;
    console.log(actionCode);
    const newPassword = this.resetPasswordForm.value.newPassword;

    // Verify the password reset code is valid.
    auth.verifyPasswordResetCode(actionCode).then((email) => {
      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.
      // Save the new password.
      auth.confirmPasswordReset(actionCode, newPassword).then((resp) => {
        // Password reset has been confirmed and new password updated.
        this.afAuth.helpers.presentToast('Password changed successfully !!');
        this.resetPasswordComplete = true;
        this.spinner = false;
      }).catch(() => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        this.spinner = false;
        this.afAuth.helpers.presentAlert('Error occurred during confirmation. The code might have expired or the password is too weak.');
      });
    }).catch(() => {
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
      this.spinner = false;
      this.afAuth.helpers.presentAlert('Invalid or expired action code. Ask user to try to reset the password again.');
    });
  }

}
